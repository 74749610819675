"use client";

import { useCallback } from "react";

import { Button, type ButtonProps } from "@revana/ui";

const ShopifyAuthButton: React.FC<ButtonProps & { shopifyDomain: string }> = ({
  shopifyDomain,
  ...props
}) => {
  const onLinkShopifyAccount = useCallback(async () => {
    window.location.href = `/api/shopify/auth/login?shopifyDomain=${shopifyDomain}`;
  }, [shopifyDomain]);

  return (
    <Button
      onClick={onLinkShopifyAccount}
      variant="tertiary"
      className="w-full text-xs"
      {...props}
    >
      {props.children ?? "Connect Store"}
    </Button>
  );
};

export default ShopifyAuthButton;
