"use client";

import PhoneIcon from "@mui/icons-material/Phone";
import { Button } from "@revana/ui";

import { api } from "@web/trpc/client";

export default function TestFlowButton() {
  const { mutate: startTestFlow } = api.store.createTestOutreach.useMutation();

  return (
    <Button
      variant="default"
      onClick={() =>
        startTestFlow({
          outreachGroupId: "a3883a36-7f55-4baf-975d-c89939c6eda5",
          customer: {
            firstName: "Subraiz",
            lastName: "Ahmed",
            phoneNumber: "+18579995006",
          },
          products: [],
        })
      }
    >
      <div className="flex items-center gap-2">
        <PhoneIcon className="text-[18px] text-white" />
        <span>Test Flow</span>
      </div>
    </Button>
  );
}
