const formatCurrency = (price: number, currency: string) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(price);

const capitalizeFirstLetter = (s: string) =>
  s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

export { formatCurrency, capitalizeFirstLetter };
