import clsx from "clsx";
import Link from "next/link";

import { type IAppInfo, type ISubAppInfo } from "../constants";

interface IDesktopSubnavigationProps {
  currentApp: IAppInfo;
  currentSubApp: ISubAppInfo | null;
  subApps: ISubAppInfo[];
}

const DesktopSubnavigation: React.FC<IDesktopSubnavigationProps> = ({
  currentApp,
  currentSubApp,
  subApps,
}) => {
  return (
    <div className="w-full border-b border-border bg-background">
      <div className="mx-auto max-w-[1400px] px-6 pt-6">
        <div className="text-lg font-bold">{currentApp.displayName}</div>
        <div className="mt-4 flex items-center gap-4">
          {subApps.map((subApp) => {
            const isActiveSubApp = currentSubApp?.path === subApp.path;

            return (
              <Link key={subApp.path} href={subApp.path}>
                <div
                  className={clsx(
                    "border-b-2 border-border pb-1 text-sm transition-all",
                    {
                      "border-primary text-card-foreground/90": isActiveSubApp,
                      "border-transparent text-card-foreground/70":
                        !isActiveSubApp,
                    }
                  )}
                >
                  {subApp.displayName}
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DesktopSubnavigation;
