"use client";

import { useCallback, useMemo } from "react";

import { DatePicker as DatePickerWithRange } from "@revana/ui";
import { usePathname } from "next/navigation";

import { APP_ROUTES } from "@web/components/Navigation/constants";
import { useDateRangeFilterContext } from "@web/libs/DateFilterProvider";
import { useStoreContext } from "@web/libs/StoreProvider";

interface DateRange {
  from: Date | undefined;
  to?: Date | undefined;
}

const DatePicker = () => {
  const { stores } = useStoreContext();
  const pathname = usePathname();

  const {
    defaultEndDate,
    defaultStartDate,
    endDateFilter,
    setEndDateFilter,
    setStartDateFilter,
    startDateFilter,
  } = useDateRangeFilterContext();

  const onSelectDate = useCallback(
    (date: DateRange | undefined) => {
      void setStartDateFilter(date?.from ?? defaultStartDate);
      void setEndDateFilter(date?.to ?? defaultEndDate);
    },
    [defaultEndDate, defaultStartDate, setEndDateFilter, setStartDateFilter]
  );

  const shouldRenderDatePicker = useMemo(() => {
    return (
      pathname === APP_ROUTES.dashboard ||
      pathname.includes(APP_ROUTES.campaigns)
    );
  }, [pathname]);

  if (!stores.length || !shouldRenderDatePicker) {
    return null;
  }

  return (
    <DatePickerWithRange
      date={{
        from: startDateFilter ?? undefined,
        to: endDateFilter ?? undefined,
      }}
      setDate={onSelectDate}
    />
  );
};

export default DatePicker;
