"use client";

import { useCallback, useState } from "react";

import { usePathname } from "next/navigation";

import DesktopNavigation from "@web/components/Navigation/DesktopNavigation";
import { useStoreContext } from "@web/libs/StoreProvider";

const Navigation: React.FC = () => {
  const { stores } = useStoreContext();
  const pathname = usePathname();

  const [isNavPinned, setIsNavPinned] = useState(false);

  const toggleNavPinned = useCallback(() => {
    setIsNavPinned(!isNavPinned);
  }, [isNavPinned]);

  if (!stores.length || pathname.includes("auth")) {
    return null;
  }

  return (
    <DesktopNavigation
      toggleNavPinned={toggleNavPinned}
      isNavPinned={isNavPinned}
    />
  );

  return (
    <nav>
      <div className="block xl:hidden">{/* <MobileNavigation /> */}</div>
      <div className="hidden xl:block">
        <DesktopNavigation
          toggleNavPinned={toggleNavPinned}
          isNavPinned={isNavPinned}
        />
      </div>
    </nav>
  );
};

export default Navigation;
