"use client";

import { usePathname } from "next/navigation";

import { useStoreContext } from "@web/libs/StoreProvider";

import DesktopSubnavigation from "./DesktopSubnavigation";
import { getCurrentApp, getCurrentSubApp } from "../constants";

const SubNavigation: React.FC = () => {
  const { stores } = useStoreContext();
  const pathname = usePathname();

  const currentApp = getCurrentApp(pathname);
  const subApps = currentApp?.subApps;

  if (!stores.length || !subApps?.length || pathname.includes("auth")) {
    return null;
  }

  const currentSubApp = getCurrentSubApp(currentApp, pathname);

  return (
    <DesktopSubnavigation
      currentApp={currentApp}
      currentSubApp={currentSubApp}
      subApps={subApps}
    />
  );
};

export default SubNavigation;
