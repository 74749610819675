"use client";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { type RouterOutputs } from "@revana/api";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  Separator,
  Label,
} from "@revana/ui";
import { usePathname, useRouter } from "next/navigation";

import { APP_ROUTES } from "@web/components/Navigation/constants";
import ShopifyAuthButton from "@web/components/ShopifyAuthButton";
import { api } from "@web/trpc/client";
import { useArray } from "@web/utils";

type StoreResponse = RouterOutputs["store"]["getUserStores"][number];
export interface IStoreContext {
  stores: StoreResponse[];
  currentStoreId: string;
  setStores: React.Dispatch<React.SetStateAction<StoreResponse[]>>;
  setCurrentStoreId: (value: string) => void;
  currentStore: StoreResponse | null;
}

const StoreContext = createContext<IStoreContext | null>(null);

export function useStoreContext() {
  const storeContext = useContext(StoreContext);

  if (!storeContext) {
    throw new Error("useStoreContext must be used within a StoreProvider");
  }

  return storeContext;
}

interface IReauthDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  store: StoreResponse;
}

const UpdatePermissionsDialog = ({
  open,
  onOpenChange,
  store,
}: IReauthDialogProps) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        hideClose
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle>Update Permissions</DialogTitle>
          <DialogDescription className="!pt-2">
            Please update the permissions for{" "}
            <span className="font-bold text-card-foreground/80">
              {store.name}
            </span>{" "}
            to enable Revana to continue working.
          </DialogDescription>
        </DialogHeader>
        <Separator />
        <div>
          <Label>Missing Permissions</Label>
          <div className="mt-4 flex flex-col gap-2">
            {store.missingScopes?.map((scope) => (
              <div
                className="text-sm capitalize text-destructive"
                key={scope.value}
              >
                - {scope.label}
              </div>
            ))}
          </div>
        </div>
        <Separator />
        <ShopifyAuthButton
          variant="default"
          className="w-full"
          shopifyDomain={store.domain}
        >
          Update Permissions
        </ShopifyAuthButton>
      </DialogContent>
    </Dialog>
  );
};

const StoreProvider: React.FC<{
  children: React.ReactNode;
  initialStores: StoreResponse[];
}> = ({ children, initialStores }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { data } = api.store.getUserStores.useQuery(undefined, {
    initialData: initialStores,
  });
  const pathname = usePathname();
  const router = useRouter();
  const { value: stores, setValue: setStores } = useArray<StoreResponse>(data);

  const utils = api.useUtils();

  // due to SSR we cant set the intial state here but need useEffect for access to setting cookies.
  const [currentStoreId, setCurrentStoreId] = useState(
    initialStores[0]?.id ?? ""
  );

  useEffect(() => {
    // check for current-store-id cookie
    const cookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("current-store-id="));

    const cookieStoreId = cookie ? cookie.split("=")[1] : null;

    const isValidStoreId = stores.find((store) => store.id === cookieStoreId);

    const newStoreId = isValidStoreId
      ? cookieStoreId
      : (initialStores?.[0]?.id ?? "");

    // if cookie is not set then set it to new store id with secure false, samesite lax, expires in 7 days and path is /

    if (!!newStoreId) {
      document.cookie = `current-store-id=${newStoreId}; samesite=lax; expires=${new Date(
        Date.now() + 7 * 24 * 60 * 60 * 1000
      ).toUTCString()}; path=/`;
    }

    setCurrentStoreId(newStoreId ?? "");
  }, [initialStores, setCurrentStoreId, stores]);

  const changeCurrentStoreId = useCallback(
    async (storeId: string) => {
      if (storeId === currentStoreId) return;

      setCurrentStoreId(storeId);
      document.cookie = `current-store-id=${storeId}; samesite=lax; expires=${new Date(
        Date.now() + 7 * 24 * 60 * 60 * 1000
      ).toUTCString()}; path=/`;
      await utils.invalidate();
      if (pathname.includes("campaigns/")) {
        router.push(APP_ROUTES.campaigns);
      }
    },
    [currentStoreId, pathname, router, utils]
  );

  const currentStore = useMemo(() => {
    return stores.find((store) => store.id === currentStoreId) ?? null;
  }, [currentStoreId, stores]);

  useEffect(() => {
    // parse const id = currentStoreId must exist in stores array
    if (!currentStore) {
      return;
    }

    if (!!currentStore.missingScopes?.length) {
      setIsDialogOpen(true);
    } else {
      setIsDialogOpen(false);
    }
  }, [currentStore]);

  const storeContextValue = {
    stores: stores,
    currentStoreId: currentStoreId,
    setStores: setStores,
    setCurrentStoreId: changeCurrentStoreId,
    currentStore: currentStore,
  };

  return (
    <StoreContext.Provider value={storeContextValue}>
      {children}
      {currentStore && (
        <UpdatePermissionsDialog
          open={isDialogOpen}
          onOpenChange={setIsDialogOpen}
          store={currentStore}
        />
      )}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
