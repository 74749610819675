"use client";

import React, {
  createContext,
  useContext,
  useMemo,
  useEffect,
  useState,
} from "react";

import { useSession } from "@revana/auth/react";
import { subDays } from "date-fns";
import { useRouter, usePathname, useSearchParams } from "next/navigation";
import { parseAsIsoDate } from "nuqs";

import { useStoreContext } from "../StoreProvider";

interface DateRangeFilterContextType {
  startDateFilter: Date | null;
  setStartDateFilter: React.Dispatch<React.SetStateAction<Date>>;
  endDateFilter: Date | null;
  setEndDateFilter: React.Dispatch<React.SetStateAction<Date>>;
  defaultStartDate: Date;
  defaultEndDate: Date;
}

const DateRangeFilterContext = createContext<DateRangeFilterContextType | null>(
  null
);

export function useDateRangeFilterContext() {
  const context = useContext(DateRangeFilterContext);
  if (!context) {
    throw new Error(
      "useDateRangeFilterContext must be used within an DateRangeFilterProvider"
    );
  }
  return context;
}

const DEFAULT_DAYS_DIFF = 28;

export function DateRangeFilterProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const router = useRouter();
  const { data: session } = useSession();
  const { stores } = useStoreContext();

  const searchParams = useSearchParams();
  const pathname = usePathname();

  const defaultStartDate = useMemo(
    () => subDays(new Date(), DEFAULT_DAYS_DIFF),
    []
  );
  const defaultEndDate = useMemo(() => new Date(), []);

  const newSearchParams = useMemo(
    () => new URLSearchParams(searchParams.toString()),
    [searchParams]
  );
  const [startDateFilter, setStartDateFilter] = useState(
    parseAsIsoDate.parse(newSearchParams.get("startDate") ?? "") ??
      defaultStartDate
  );
  const [endDateFilter, setEndDateFilter] = useState(
    parseAsIsoDate.parse(newSearchParams.get("endDate") ?? "") ?? defaultEndDate
  );

  useEffect(() => {
    const isOnAuthPage = pathname.includes("auth");
    const isOnboardingPage = pathname.includes("onboarding");
    if (!session || !stores.length || isOnAuthPage || isOnboardingPage) {
      return;
    }
    const newStartDate = parseAsIsoDate.serialize(startDateFilter);
    const newEndDate = parseAsIsoDate.serialize(endDateFilter);

    if (
      !!newStartDate &&
      newStartDate !== parseAsIsoDate.serialize(defaultStartDate)
    ) {
      newSearchParams.set("startDate", newStartDate);
    } else {
      newSearchParams.delete("startDate");
    }

    if (
      !!newEndDate &&
      newEndDate !== parseAsIsoDate.serialize(defaultEndDate)
    ) {
      newSearchParams.set("endDate", newEndDate);
    } else {
      newSearchParams.delete("endDate");
    }

    router.push(`${pathname}?${newSearchParams.toString()}`);
  }, [
    pathname,
    searchParams,
    router,
    startDateFilter,
    endDateFilter,
    defaultStartDate,
    defaultEndDate,
    newSearchParams,
    session,
    stores,
  ]);

  return (
    <DateRangeFilterContext.Provider
      value={{
        defaultEndDate,
        defaultStartDate,
        endDateFilter,
        setEndDateFilter,
        startDateFilter,
        setStartDateFilter,
      }}
    >
      {children}
    </DateRangeFilterContext.Provider>
  );
}
